<template>
  <svg id="Grupo_12063" data-name="Grupo 12063" xmlns="http://www.w3.org/2000/svg" width="414.855" height="189.305" viewBox="0 0 414.855 189.305">
    <path id="Trazado_371" data-name="Trazado 371" d="M782.128,34.662a22.326,22.326,0,0,0-21.789-22.294,28.483,28.483,0,0,0-46.971,0A22.3,22.3,0,0,0,696,47.988a28.488,28.488,0,0,0,26.2,39.646,28.946,28.946,0,0,0,3.745-.244v66.062h21.808V87.391a28.961,28.961,0,0,0,3.743.244,28.488,28.488,0,0,0,26.2-39.646A22.161,22.161,0,0,0,782.128,34.662Z" transform="translate(-367.271 36)" :fill="`#${color}`"/>
    <g id="Grupo_627" data-name="Grupo 627" transform="translate(0 0)">
        <path id="Trazado_365" data-name="Trazado 365" d="M-281.085,291.092h-12.041V141.48a6.106,6.106,0,0,0-6.107-6.107h-45.8V120.107A6.106,6.106,0,0,0-351.141,114H-393.5a6.106,6.106,0,0,0-6.106,6.107v15.267h-4.963a6.106,6.106,0,0,0-6.106,6.107V291.092h-12.041a6.22,6.22,0,0,0-6.272,5.814,6.107,6.107,0,0,0,6.1,6.4h141.978a6.108,6.108,0,0,0,6.1-6.4,6.22,6.22,0,0,0-6.272-5.814Zm-85.7-50.762h-6.869a6.107,6.107,0,0,1-6.106-6.107,6.107,6.107,0,0,1,6.106-6.106h6.869a6.108,6.108,0,0,1,6.108,6.106,6.108,6.108,0,0,1-6.108,6.107Zm0-22.9h-6.869a6.106,6.106,0,0,1-6.106-6.107,6.107,6.107,0,0,1,6.106-6.107h6.869a6.108,6.108,0,0,1,6.108,6.107,6.106,6.106,0,0,1-6.108,6.107Zm0-22.9h-6.869a6.106,6.106,0,0,1-6.106-6.106,6.107,6.107,0,0,1,6.106-6.107h6.869a6.108,6.108,0,0,1,6.108,6.107,6.107,6.107,0,0,1-6.108,6.106Zm0-22.9h-6.869a6.107,6.107,0,0,1-6.106-6.106,6.107,6.107,0,0,1,6.106-6.107h6.869a6.108,6.108,0,0,1,6.108,6.107,6.108,6.108,0,0,1-6.108,6.106Zm36.259,68.7H-337.4a6.107,6.107,0,0,1-6.107-6.107,6.107,6.107,0,0,1,6.107-6.106h6.871a6.107,6.107,0,0,1,6.107,6.106,6.107,6.107,0,0,1-6.107,6.107Zm0-22.9H-337.4a6.106,6.106,0,0,1-6.107-6.107,6.107,6.107,0,0,1,6.107-6.107h6.871a6.107,6.107,0,0,1,6.107,6.107,6.106,6.106,0,0,1-6.107,6.107Zm0-22.9H-337.4a6.107,6.107,0,0,1-6.107-6.106,6.107,6.107,0,0,1,6.107-6.107h6.871a6.107,6.107,0,0,1,6.107,6.107,6.107,6.107,0,0,1-6.107,6.106Zm0-22.9H-337.4a6.107,6.107,0,0,1-6.107-6.106,6.107,6.107,0,0,1,6.107-6.107h6.871a6.107,6.107,0,0,1,6.107,6.107,6.107,6.107,0,0,1-6.107,6.106Zm-33.586,128.62" transform="translate(428.999 -114)" :fill="`#${color}`"/>
    </g>
    <g id="Grupo_630" data-name="Grupo 630" transform="translate(164.261 70)">
        <path id="Trazado_369" data-name="Trazado 369" d="M124.678,66.224,67.783,14.382a6.473,6.473,0,0,0-8.759,0L2.129,66.224a6.5,6.5,0,0,0,4.38,11.309H15.6v51.942a3.729,3.729,0,0,0,3.729,3.729H50.511a3.729,3.729,0,0,0,3.729-3.729V97.938H72.568v31.537A3.729,3.729,0,0,0,76.3,133.2h31.184a3.729,3.729,0,0,0,3.729-3.729V77.533H120.3a6.5,6.5,0,0,0,4.379-11.309Z" transform="translate(0 -12.675)" :fill="`#${color}`"/>
        <path id="Trazado_370" data-name="Trazado 370" d="M372.746,68.893V46.459a3.729,3.729,0,0,0-3.729-3.729H343.973Z" transform="translate(-258.781 -35.286)" :fill="`#${color}`"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Urbano',
  props: {
    color: String
  }
}
</script>
