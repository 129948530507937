var render, staticRenderFns
import script from "./ParticipacionCiudadana.vue?vue&type=script&lang=js&"
export * from "./ParticipacionCiudadana.vue?vue&type=script&lang=js&"
import style0 from "./ParticipacionCiudadana.vue?vue&type=style&index=0&id=18e4157c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e4157c",
  null
  
)

export default component.exports