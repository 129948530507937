import { render, staticRenderFns } from "./DetalleDeLaCasilla.vue?vue&type=template&id=3efeb899&scoped=true&"
import script from "./DetalleDeLaCasilla.vue?vue&type=script&lang=js&"
export * from "./DetalleDeLaCasilla.vue?vue&type=script&lang=js&"
import style0 from "./DetalleDeLaCasilla.vue?vue&type=style&index=0&id=3efeb899&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3efeb899",
  null
  
)

export default component.exports