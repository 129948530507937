<template>
  <div>
    <DetalleDeCasillaMovil v-if="anchoPantalla < 992" />
    <DetalleDeCasillaEscritorio v-else />
  </div>
</template>

<script>
import DetalleDeCasillaMovil from '@/components/Movil/vistas/VotosPorCandidaturas/DetalleDeCasilla'
import DetalleDeCasillaEscritorio from '@/components/Escritorio/vistas/VotosPorCandidatura/DetalleDeLaCasilla'

export default {
  name: 'VotosPorCandidatura',
  components: {
    DetalleDeCasillaMovil,
    DetalleDeCasillaEscritorio
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Entidad - Votos Por Candidaturas - Detalle de Casilla'
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  }
}
</script>
