<template>
  <div >
    <vue-c3 id="graficaListaNominal" :handler="handler"></vue-c3>
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';

export default {
  name: 'GraficaListaNominal',
  components: {
    VueC3,
  },
  props: {
    color: String,
    // valores: Object,
    numero: String,
    porcentaje: String,
    value: Boolean,
  },
  data() {
    return {
      handler: new Vue(),
    };
  },
  methods: {
    cambiarEstilo() {
      if (this.$store.state.esOscuro) {
        const element1 = document.getElementsByClassName('c3-gauge-value')[0];
        const element2 = document.getElementsByClassName('c3-chart-arcs-background')[0];
        element1.style.fill = 'white';
        element2.style.fill = '#2C2C2C';
      } else {
        const element1 = document.getElementsByClassName('c3-gauge-value')[0];
        const element2 = document.getElementsByClassName('c3-chart-arcs-background')[0];
        const element3 = document.getElementsByClassName('.c3-chart-arc')[0];
        element1.style.fill = '#D1308A';
        element2.style.fill = '#E3E3E3';
        if (element3) {
          element3.style.stroke = '#E3E3E3';
        }
      }
    },
  },
  watch: {
    value() {
      this.cambiarEstilo();
    },
  },
  mounted() {
    const options = {
      data: {
        columns: [
          ['data', this.porcentaje],
        ],
        type: 'gauge',
      },
      color: {
        pattern: [`#${this.color}`],
      },
      gauge: {
        label: {
          format: () => this.numero,
          show: false,
        },
      },
      size: {
        height: 200,
      },
      tooltip: {
        show: false,
        point: false,
      },
      interaction: {
        enabled: false,
      },
    };

    this.handler.$emit('init', options);
    this.cambiarEstilo();
  },
};

</script>
