export default function obtenerColoresDeAvatar(handler) {
  if (handler.$store.state.esOscuro) {
    return {
      relleno: '1d1d1d',
      contorno: 'e97ebd',
    };
  }
  return {
    relleno: 'd2127e',
    contorno: '707070',
  };
}
