<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 69.41 73.02">
  <defs>
    <clipPath id="clip-path">
      <path d="M65.28,36.1a31.09,31.09,0,0,1-31.1,31.1C-7.07,65.56-7.06,6.63,34.18,5a31.09,31.09,0,0,1,31.1,31.1Z" style="fill:none"/>
    </clipPath>
  </defs>
  <g id="Grupo_7632" data-name="Grupo 7632">
    <g id="Grupo_7577" data-name="Grupo 7577">
      <path id="Trazado_5288-2" data-name="Trazado 5288-2" d="M65.16,36.36a31,31,0,0,1-31,31h0C-7,65.76-7,7,34.13,5.32a31,31,0,0,1,31,31Z"
      :style="`fill:#${colores.relleno};stroke:#${colores.contorno};stroke-width:0.5px`"/>
    </g>
    <g id="Grupo_7623" data-name="Grupo 7623">
      <g style="clip-path:url(#clip-path)">
        <g id="Grupo_7622" data-name="Grupo 7622">
          <g id="Grupo_7621" data-name="Grupo 7621">
            <g id="Grupo_7585" data-name="Grupo 7585">
              <g id="Grupo_7584" data-name="Grupo 7584">
                <rect id="Rectángulo_16861" data-name="Rectángulo 16861" x="47.68" y="68.52" width="7.67" height="29.93" style="fill:#dadada"/>
              </g>
            </g>
            <g id="Grupo_7587" data-name="Grupo 7587">
              <g id="Grupo_7586" data-name="Grupo 7586">
                <path id="Trazado_5289" data-name="Trazado 5289" d="M47.68,56.07a7.61,7.61,0,0,1,7.68,7.54v4.91H47.68Z" style="fill:#edecec"/>
              </g>
            </g>
            <g id="Grupo_7589" data-name="Grupo 7589">
              <g id="Grupo_7588" data-name="Grupo 7588">
                <rect id="Rectángulo_16862" data-name="Rectángulo 16862" x="12.69" y="68.52" width="7.67" height="29.44" style="fill:#dadada"/>
              </g>
            </g>
            <g id="Grupo_7591" data-name="Grupo 7591">
              <g id="Grupo_7590" data-name="Grupo 7590">
                <path id="Trazado_5290" data-name="Trazado 5290" d="M20.36,56.07a7.6,7.6,0,0,0-7.67,7.54v4.91h7.67Z" style="fill:#edecec"/>
              </g>
            </g>
            <g id="Grupo_7594" data-name="Grupo 7594">
              <g id="Grupo_7593" data-name="Grupo 7593">
                <g id="Grupo_7592" data-name="Grupo 7592">
                  <path id="Trazado_5291" data-name="Trazado 5291" d="M40.94,52.1l-14.34,0c-7.93.38-7.92,13.71,0,14.07l14.33,0C48.88,65.8,48.88,52.46,40.94,52.1Z" style="fill:#fff"/>
                </g>
              </g>
            </g>
            <g id="Grupo_7597" data-name="Grupo 7597">
              <g id="Grupo_7596" data-name="Grupo 7596">
                <g id="Grupo_7595" data-name="Grupo 7595">
                  <rect id="Rectángulo_16863" data-name="Rectángulo 16863" x="22.05" y="63.85" width="23.16" height="70.59" style="fill:#fff"/>
                </g>
              </g>
            </g>
            <g id="Grupo_7599" data-name="Grupo 7599">
              <g id="Grupo_7598" data-name="Grupo 7598">
                <path id="Trazado_5292" data-name="Trazado 5292" d="M26.39,52.33c-4.1-.34-7.43,3.26-7.7,8.09h0v52.51h9.78V54.87A2.34,2.34,0,0,0,26.39,52.33Z" style="fill:#fff"/>
              </g>
            </g>
            <g id="Grupo_7604" data-name="Grupo 7604">
              <g id="Grupo_7601" data-name="Grupo 7601">
                <g id="Grupo_7600" data-name="Grupo 7600">
                  <path id="Trazado_5293" data-name="Trazado 5293" d="M20,66.21H25.3A2.21,2.21,0,0,0,27.51,64h0V54.55a2.21,2.21,0,0,0-2.21-2.22h0a7.55,7.55,0,0,0-7.55,7.55V64A2.21,2.21,0,0,0,20,66.21Z" style="fill:#fff"/>
                </g>
              </g>
              <g id="Grupo_7603" data-name="Grupo 7603">
                <g id="Grupo_7602" data-name="Grupo 7602">
                  <rect id="Rectángulo_16864" data-name="Rectángulo 16864" x="17.74" y="60.43" width="9.78" height="52.51" style="fill:#fff"/>
                </g>
              </g>
            </g>
            <g id="Grupo_7607" data-name="Grupo 7607">
              <g id="Grupo_7606" data-name="Grupo 7606">
                <g id="Grupo_7605" data-name="Grupo 7605">
                  <path id="Trazado_5294" data-name="Trazado 5294" d="M37.93,52.1H29.29C29.75,58.74,37.49,58.73,37.93,52.1Z" style="fill:#edecec"/>
                </g>
              </g>
            </g>
            <g id="Grupo_7609" data-name="Grupo 7609">
              <g id="Grupo_7608" data-name="Grupo 7608">
                <path id="Trazado_5295" data-name="Trazado 5295" d="M40.78,52.33c4.1-.34,7.44,3.26,7.7,8.09h0v52.51H38.73V54.87A2.33,2.33,0,0,1,40.78,52.33Z" style="fill:#fff"/>
              </g>
            </g>
            <g id="Grupo_7614" data-name="Grupo 7614">
              <g id="Grupo_7611" data-name="Grupo 7611">
                <g id="Grupo_7610" data-name="Grupo 7610">
                  <path id="Trazado_5296" data-name="Trazado 5296" d="M48.6,66.21H41.47A2.46,2.46,0,0,1,39,63.75h0v-9a2.46,2.46,0,0,1,2.46-2.46h1.21a8.38,8.38,0,0,1,8.38,8.37h0v3a2.46,2.46,0,0,1-2.46,2.46Z" style="fill:#fff"/>
                </g>
              </g>
              <g id="Grupo_7613" data-name="Grupo 7613">
                <g id="Grupo_7612" data-name="Grupo 7612">
                  <rect id="Rectángulo_16865" data-name="Rectángulo 16865" x="39.01" y="60.43" width="12.04" height="52.51" style="fill:#fff"/>
                </g>
              </g>
            </g>
            <g id="Grupo_7616" data-name="Grupo 7616">
              <g id="Grupo_7615" data-name="Grupo 7615">
                <rect id="Rectángulo_16866" data-name="Rectángulo 16866" x="30.51" y="40.29" width="6.66" height="11.74" style="fill:#de8b72"/>
              </g>
            </g>
            <g id="Grupo_7618" data-name="Grupo 7618">
              <g id="Grupo_7617" data-name="Grupo 7617">
                <path id="Trazado_5297" data-name="Trazado 5297" d="M48.5,28.39c0,9.29-6.36,16.83-14.25,16.85-18.93-.83-19-32.78-.06-33.66C42.08,11.56,48.48,19.09,48.5,28.39Z" style="fill:#e7987b"/>
              </g>
            </g>
            <g id="Grupo_7620" data-name="Grupo 7620">
              <g id="Grupo_7619" data-name="Grupo 7619">
                <path id="Trazado_5298" data-name="Trazado 5298" d="M37.17,52c-.11,5.5-6.53,5.5-6.66,0C30.62,46.53,37,46.52,37.17,52Z" style="fill:#de8b72"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    colores: Object
  }
}
</script>
