function useFiltrarDatosSeccion(distrito, seccion, casilla = null) {
  // eslint-disable-next-line arrow-body-style
  const filtrarDatosSeccion = (estado, area) => {
    return estado[area][distrito - 1]
      .datos_secciones.filter((seccionInterna) => {
        const seccionInternaParseada = parseInt(seccionInterna.seccion_id, 10);
        const seccionParseada = parseInt(seccion, 10);
        return seccionInternaParseada === seccionParseada;
      })[0];
  };

  // eslint-disable-next-line arrow-body-style
  const filtrarDatosCasilla = (estado, area) => {
    return estado[area][distrito - 1]
      .datos_secciones.filter((seccionInterna) => {
        const seccionInternaParseada = parseInt(seccionInterna.seccion_id, 10);
        const seccionParseada = parseInt(seccion, 10);
        return seccionInternaParseada === seccionParseada;
      })[0]
      .datos_casillas
      .filter((casillaInterna) => casillaInterna.casilla_tipo === casilla)[0];
  };

  return {
    filtrarDatosSeccion,
    filtrarDatosCasilla,
  };
}

export default useFiltrarDatosSeccion;
