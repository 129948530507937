<template>
    <div :class="this.obtenerClaseGanador">
        <div class="grilla-datos-candidatura">
          <div class="foto" style="text-align:center">
            <img :src="this.candidato.candidato_foto_url" style="width:60px; height:60px" />
          </div>
          <div class="nombre text-center mb-4">
            <b>{{this.candidato.candidato_nombre}}</b>
            <br/>
            <b>{{candidato.candidato_apellidos}}</b>
          </div>
        </div>
        <div class="partidos" style="text-align:center">
                    <ul>
                      <li>
                        <img :src="candidato.candidatura_logo" style="max-height:40px;"> 
                      </li>
                    </ul>
        </div>
        <div class="grilla-resumen mb-2" style="background-color:#F4F4F4">
          <div class="total text-left">
            <p class="mb-0 font-weight-bold">
              Votos <br/> 
              <span>
                {{ this.candidato.candidatura_total_votos_numero }}
              </span>
            </p>
          </div>
          <div class="porcentaje text-right">
            <p class="mb-0 font-weight-bold">
              <span>
                {{ this.candidato.candidatura_total_votos_porcentaje }}
              </span>
            </p>
          </div>
        </div>
      </div>
</template>
<script>
import Avatar from '@/components/Avatar'
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar'

export default {
  name: 'TarjetaResumenVotacionCandidato',
  components: {
    Avatar
  },
  props: {
    candidato: Object,
    ganador: Number
  },
  computed: {
    colores () {
      return obtenerColoresDeAvatar(this)
    },
    obtenerClaseGanador () {
      if (this.candidato.candidato_id == this.ganador){
        return 'resumen-voto-candidatura ganador'
      }else{
        return 'resumen-voto-candidatura'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.resumen-voto-candidatura {
  padding: 3px;
  border: 1px solid #181A2F;
  //height: 25vh;
  max-width: 150px;
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
    margin-top:10px;
  }
}

.ganador {
  border-color: #D2127E;
  border-width: 3px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .total {
    p > span {
      color: #D2127E;
    }
  }
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #181A2F;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#181A2F, 10%);
}
</style>
