<template>
  <svg id="Grupo_12062" data-name="Grupo 12062" xmlns="http://www.w3.org/2000/svg" width="408.498" height="191.437" viewBox="0 0 408.498 191.437">
  <path id="Trazado_361" data-name="Trazado 361" d="M782.128,34.662a22.326,22.326,0,0,0-21.789-22.294,28.483,28.483,0,0,0-46.971,0A22.3,22.3,0,0,0,696,47.988a28.488,28.488,0,0,0,26.2,39.646,28.946,28.946,0,0,0,3.745-.244v66.062h21.808V87.391a28.961,28.961,0,0,0,3.743.244,28.488,28.488,0,0,0,26.2-39.646A22.161,22.161,0,0,0,782.128,34.662Z" transform="translate(-493.625 37.984)" :fill="`#${color}`"/>
  <path id="Trazado_364" data-name="Trazado 364" d="M-111.189,205.293a16.94,16.94,0,0,0,7.257-13.893l-15.161-19.529a14.807,14.807,0,0,0,8.665-13.457L-145.936,114l-35.508,44.414a14.806,14.806,0,0,0,8.7,13.472L-187.94,191.4a16.941,16.941,0,0,0,7.289,13.916l-13.7,17.754a19.1,19.1,0,0,0,19.084,19.082,19.048,19.048,0,0,0,14.665-6.888,19.227,19.227,0,0,0,2.255,2.284v47.33h24.823v-47.33a19.262,19.262,0,0,0,2.255-2.284,19.048,19.048,0,0,0,14.665,6.888A19.1,19.1,0,0,0-97.521,223.07Z" transform="translate(506.02 -94)" :fill="`#${color}`"/>
  <path id="Trazado_366" data-name="Trazado 366" d="M-1975.057-12.751l22.744-22.635a3.294,3.294,0,0,1,4.647,0l20.446,20.347,20.443-20.347a3.3,3.3,0,0,1,4.651,0l22.743,22.635a3.293,3.293,0,0,1,.973,2.337V14.637h40.869a3.3,3.3,0,0,1,2.608,1.282l26.594,34.438c.069.092.134.185.194.28a3.286,3.286,0,0,1,.88,2.235V99.681a3.3,3.3,0,0,1-3.3,3.3h-162.171a3.3,3.3,0,0,1-3.3-3.3v-110.1A3.3,3.3,0,0,1-1975.057-12.751Zm41.3,73.435,5.8,10.858,5.8-10.858Zm116.491-11.108-21.888-28.347h-82.032l21.929,28.347Zm-102.681,20.973-4.269,7.987,4.269,7.983Zm-7.294,25.835h5.089l-5.8-10.858-5.8,10.858Zm-8.719-9.865,4.269-7.983-4.269-7.987ZM-1969.436-.855h10.765a3.3,3.3,0,0,1,3.3,3.3,3.3,3.3,0,0,1-3.3,3.3h-10.765V15.3h18.474a3.3,3.3,0,0,1,3.3,3.3,3.3,3.3,0,0,1-3.3,3.3h-18.474v74.5h11.2V53.014a3.28,3.28,0,0,1,.687-2.011l26.771-34.738c.036-.043.073-.082.109-.125.039-.063.083-.119.125-.178v-25L-1949.989-28.4l-19.447,19.355Z" transform="translate(1976.029 90)" :fill="`#${color}`"/>
</svg>

</template>

<script>
export default {
  name: 'NoUrbano',
  props: {
    color: String
  }
}
</script>
