<template>
  <Layout :seccion="true">
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <div class="grilla-selects">
          <div class="secciones">
            <select v-model="seccion" @change="cambiarSeccion">
              <option v-for="(seccion, index) in listaSecciones"
                    :key="index"
                    :value="seccion.seccion_id">
                Sección {{seccion.seccion_id}}
              </option>
            </select>
          </div>
          <div class="casillas">
            <select v-model="casilla" @change="irADetalleDeCasilla">
              <option v-for="(casilla, index) in listaCasillas.datos_casillas"
              :key="index"
              :value="casilla.tipo_casilla">{{casilla.tipo_casilla}}</option>
            </select>
          </div>
        </div>

        <h4>
          {{ tituloEleccion }} -
          <b class="primario">Sección Casilla</b>
        </h4>

        <p>
          <router-link :to="`/${eleccion}/ENT/VPC`" class="estado-volver">
            Baja California Sur
          </router-link> /
          <router-link :to="`/${eleccion}/DET/VPC/${distrito}`" class="estado-volver">
            <span
              v-if="tituloEleccion !== 'Ayuntamiento'"
            >
              Distrito {{ distrito }}. {{ distritoNombre }}
            </span>
            <span v-else>
              Municipio {{ distrito }}. {{ ayuntamiento.municipio_descripcion }}
            </span>
          </router-link> /
          <router-link :to="`/${eleccion}/Secciones/${distrito}/${seccion}`" class="estado-volver">
            Sección {{ seccion }}
          </router-link> /
          <b>{{ casilla }}</b>
        </p>

        <p>
          Cantidad de votos en cada una de las casillas de esta Sección,
          conforme a la información de las Actas de Escrutinio y Cómputo.
        </p>

        <div class="totalVotos mb-4">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumen.total_votos_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{resumen.total_votos_porcentaje}}%
          </p>
        </div>

        <div class="votosEnActasContabilizadas mb-5">
          <p class="titulo">
            Votos en Actas Contabilizadas
          </p>

          <p class="mb-0">
            Ver Acta Digitalizada en:
          </p>

          <a
            :href="calcularUrlActaDigitalizada(votosXCasilla.datos_votos[1])"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button class="btn btn-primary btn-ver-acta">
              <font-awesome-icon
                :icon="['far', 'file']"
                class="mx-1"
              ></font-awesome-icon>
              <font-awesome-icon icon="print" class="mx-1" />
              Ver Acta
            </button>
          </a>
        </div>

        <div
          class="row"
        >
          <div
            class="col-12 col-sm-6 mb-4"
            v-for="(candidato, index) in votosXCasillaTarjetas.datos_votos"
            :key="index"
          >
            <!--<div class="resumen-voto-candidatura" :class="{ 'ganador': candidato.ganador }">-->
            <div class="resumen-voto-candidatura">
              <div class="grilla-datos-candidatura">
                <div class="foto">
                  <!--<Avatar :colores="colores" />-->
                  <img
                    :src="candidato.candidatura_logo"
                    :alt="candidato.candidatura_nombre"
                    style="max-height:40px;"
                  >
                </div>
                <div class="nombre">
                  <!-- <b>{{candidato.candidatura_nombre}}</b> -->
                </div>
                <div class="seleccion">
                  <!-- <input type="checkbox" v-model="candidato.seleccionado"> -->
                </div>
              </div>
              <div class="grilla-votos mb-2">
                <div class="total text-left">
                  <p class="mb-0 font-weight-bold">
                    Total de votos <br />
                    <span>
                      {{ candidato.candidatura_votos_cantidad }}
                    </span>
                  </p>
                </div>
                <div class="porcentaje text-right">
                  <p class="mb-0 font-weight-bold">
                    <span>
                      {{ candidato.candidatura_votos_porcentaje }}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="resumenDeLaVotacion mb-4">
          <p class="titulo">
            Resumen de la votación
          </p>

          <ResumenVotacion texto="Candidaturas no registradas"
            :numero="resumen.candidaturas_no_registradas_numero"
            :porcentaje="resumen.candidaturas_no_registradas_porcentaje"
          />
          <ResumenVotacion texto="Nulos"
            :numero="resumen.nulos_numero"
            :porcentaje="resumen.nulos_porcentaje"
          />
        </div>

        <EstadisticaCasilla
          :seccion="seccion"
          :casilla="casilla"
          :estadisticas="estadisticas"
        />

        <ObservacionesActas />

      </div>
    </div>
  </Layout>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import useDetalleCasilla from '@/composables/UseDetalleCasilla';
import Layout from '@/components/Movil/layouts/Layout';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas';
import EstadisticaCasilla from '@/components/Movil/componentes/EstadisticaCasilla.vue';
// import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default defineComponent({
  name: 'VpcDetalleDelDistritoYSeccionMovil',
  components: {
    Layout,
    ResumenVotacion,
    ObservacionesActas,
    EstadisticaCasilla,
  },
  computed: {
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
  },
  setup(_, { root }) {
    return {
      ...useDetalleCasilla(root),
    };
  },
});
</script>

<style lang="scss" scoped>
.ganador {
  border: 3px solid #d2127e;
  .grilla-votos {
    p > span {
      color: #d2127e;
    }
  }
}

.resumen-voto-candidatura {
  background-color: #f5f5f5;
  padding: 10px;

  .grilla-datos-candidatura {
    display: grid;
    grid-template-columns: 75px 1fr 30px;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    .foto {
      img {
        max-height: 50px;
        display: block;
        margin: 0 auto;
        padding: 3px;
      }
    }

    .nombre {
      font-size: 16px;
    }
  }

  .grilla-votos, .grilla-distribucion-votos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;
  }

  .grilla-distribucion-votos {
    background-color: #d3d3ec;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    padding: 10px;
  }
}

.btn-link {
  border-radius: 15px;
  background-color: #34344e;
  border-color: transparent;
}

.grilla-selects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 20px;

  select {
    border: 1px solid #fff;
    border-radius: 15px;
    width: 100%;
    padding: 15px;
    appearance: none;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }

  .secciones > select {
    background-color: #34344e;
  }
  .casillas > select {
    background-color: #D3097F;
  }
}

.votosEnActasContabilizadas {
  button.btn-ver-acta {
    background-color: #D3097F;
    width: 100%;
    max-width: 250px;
    border-color: transparent !important;
    box-shadow: none !important;
    font-size: 18px;
  }
}
</style>
