<template>
  <div class="grilla-resumen">
    <div class="votos ml-4">
      <p>
        {{ texto }} <br />
        {{ numero }}
      </p>
    </div>
    <div class="porcentaje">
      <p>
        {{ porcentaje }}%
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResumenVotacion',
  props: {
    numero: String,
    porcentaje: String,
    texto: String,
  },
};
</script>

<style lang="scss" scoped>
.grilla-resumen {
  display: grid;
  grid-template-columns: 1fr 75px;
  align-items: flex-end;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;

  p {
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 10px;
  }

  .porcentaje {
    text-align: right;
  }
}
</style>
