<template>
  <div class="estadistica-entidad">
    <div class="votosEnActasContabilizadas">
      <p class="titulo">
        Estadística Entidad
      </p>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 col-xl-4">
        <div class="estadisticaEntidad mb-3">
          <h5 class="font-weight-bold mb-3">
            <u>Actas</u>
          </h5>
          <div class="grilla-estadisticas mb-4">
            <div class="esperadas">
              <ProgresoVertical
                class="mb-3"
                :avance="obtenerAvance(estadisticas.actas_esperadas_porcentaje)"
                :color="colorEsperadas"
              />
            </div>
            <div class="capturadas">
              <ProgresoVertical
                class="mb-3"
                :avance="obtenerAvance(estadisticas.actas_capturadas_porcentaje)"
                :color="colorCapturadas"
              />
            </div>
            <div class="contabilizadas">
              <ProgresoVertical
                class="mb-3"
                :avance="obtenerAvance(estadisticas.actas_contabilizadas_porcentaje)"
                :color="colorContabilizadas"
              />
            </div>
            <div class="esperadas">
              <p>
                <span>{{ estadisticas.actas_esperadas_numero }}</span> <br />
                <span>{{ estadisticas.actas_esperadas_porcentaje }}% </span><br />
                Esperadas
              </p>
            </div>
            <div class="capturadas">
              <p>
                <span>{{ estadisticas.actas_capturadas_numero }}</span> <br />
                <span>{{ estadisticas.actas_capturadas_porcentaje }}% </span><br />
                Capturadas
              </p>
            </div>
            <div class="contabilizadas">
              <p>
                <span>{{ estadisticas.actas_contabilizadas_numero }} </span><br />
                <span>{{ estadisticas.actas_contabilizadas_porcentaje }}% </span><br />
                Contabilizadas
              </p>
            </div>
          </div>

          <h5 class="font-weight-bold mb-3">
            Actas Contabilizadas por ubicación de casillas:
          </h5>

          <div class="grilla-estadistica-urbana-nourbana mb-3">
            <div class="urbana">
              <urbano :color="colorUrbano" />
            </div>
            <div class="no-urbana">
              <no-urbano :color="colorNoUrbano" />
            </div>
            <div :style="styleUrbano">
              <h5 :style="styleLetter">
                {{ estadisticas.actas_casilla_urbana_numero }} <br />
                <small>Urbanas</small>
              </h5>
            </div>
            <div :style="styleNoUrbano">
              <h5 :style="styleLetter">
                {{ estadisticas.actas_casilla_nourbana_numero }} <br />
                <small>No Urbanas</small>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-4">
        <div class="listaNominal mb-5">
          <h5 class="font-weight-bold mb-3">
            Lista Nominal
          </h5>

          <p class="mb-0">
            El <b>número de personas</b> registradas en la Lista Nominal de las Actas Contabilizadas se utiliza para obtener el porcentaje de Participación ciudadana calculando:
          </p>
          <p class="text-center">
            % de Participación Ciudadana = Total de votos de las Actas contabilizadas, por 100, dividido entre la Lista Nominal de Actas contabilizadas.
          </p>

          <div class="row listaNominal-actasContabilizadas">
            <div class="col-6 ml-auto">
              <p class="text-right mb-0">
                Lista Nominal de <br />
                Actas Contabilizadas
              </p>

              <h4 class="text-center font-weight-bold primario">
                {{ estadisticas.valor_lista_nominal_actas_contabilizadas_numero }}
              </h4>
            </div>
          </div>
          <GraficaListaNominal class="mb-4"
            v-bind:value="this.$store.state.esOscuro"
            :color="colorUrbano"
            :numero="estadisticas.valor_lista_nominal_oficial_numero"
            :porcentaje="estadisticas.valor_lista_nominal_actas_contabilizadas_porcentaje"
            :key="randomKey"
          />

        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-4">
        <div class="participacion-ciudadana">
          <p class="font-weight-bold">
            Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas
          </p>

          <h2 class="text-center" :class="{'primario': !this.$store.state.esOscuro}">
            {{ estadisticas.participacion_ciudadana_porcentaje }}%
          </h2>
          <p class="small text-center">
            <!-- Porcentaje calculado <b>sin conderar</b> la votación<br />
            en <b>Casillas Especiales.</b> -->
            {{ estadisticas.leyenda_porcentaje }}
          </p>

          <div class="votosTotalesAvance mb-4">
            <div class="grilla mb-2">
              <div class="leyenda">
                <p class="font-weight-bold mb-0" v-if="eleccion === 'G'">
                  Votos en Casillas Básicas, Contiguas,
                  Extraordinarias y Mesas de Escrutinio
                </p>
                <p class="font-weight-bold mb-0" v-else>
                  Votos en Casillas Básicas, <br />
                  Contiguas y Extraordinarias
                </p>
              </div>
              <div class="total">
                <h4 class="mb-0">
                  {{ estadisticas.votos_casilla_basica_contigua_extraordinaria_numero}}
                </h4>
              </div>
            </div>
            <b-progress
              max="100"
              :value="estadisticas.votos_casilla_basica_contigua_extraordinaria_porcentaje"
            />
          </div>

          <div class="votosTotalesAvance">
            <div class="grilla mb-2">
              <div class="leyenda">
                <p class="font-weight-bold mb-0">
                  Votos en Casillas Especiales
                  <template v-if="this.$route.params.eleccion === 'D'">MR</template>
                </p>
              </div>
              <div class="total">
                <h4 class="mb-0">
                    {{
                      this.$route.params.eleccion === 'D' ?
                      estadisticas.votos_casilla_especial_mr_numero :
                      estadisticas.votos_casilla_especial_numero
                    }}
                </h4>
              </div>
            </div>
            <b-progress max="100" :value="this.$route.params.eleccion === 'D' ?
              estadisticas.votos_casilla_especial_mr_porcentaje :
              estadisticas.votos_casilla_especial_porcentaje" />
          </div>

           <div class="votosTotalesAvance" v-if="this.$route.params.eleccion === 'D'">
            <div class="grilla mb-2">
              <div class="leyenda">
                <p class="font-weight-bold mb-0">
                  Votos en Casillas Especiales RP
                </p>
              </div>
              <div class="total">
                <h4 class="mb-0">
                  {{ estadisticas.votos_casilla_especial_rp_numero }}
                </h4>
              </div>
            </div>
            <b-progress max="100" :value="estadisticas.votos_casilla_especial_rp_porcentaje" />
          </div>

          <div class="total-votos row mt-5">
            <div class="col-6">
              <h4>
                <span>Total</span> de Votos
              </h4>
            </div>
            <div class="col-6" :class="{'primario' : this.$store.state.esOscuro}">
              <h4>
                <strong>
                  {{ estadisticas.total_votos_general_numero }}
                </strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical.vue';
import Urbano from '@/components/Movil/componentes/Iconos/Urbano';
import GraficaListaNominal from '@/components/Movil/componentes/GraficaListaNominal';
import NoUrbano from '@/components/Movil/componentes/Iconos/NoUrbano';

export default {
  name: 'EstadisticaEntidad',
  components: {
    ProgresoVertical,
    GraficaListaNominal,
    Urbano,
    NoUrbano,
  },
  data() {
    return {
      randomKey: Math.random(),
    };
  },
  computed: {
    estadisticas() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelEntidad;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelEntidad;
      }
      return this.$store.state.gEstadisticasNivelEntidad;
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'd2127e';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return '79144c';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return 'efb5d3';
    },
    colorUrbano() {
      if (this.$store.state.esOscuro) {
        return 'F1B1D7';
      }
      return 'd2127e';
    },
    colorNoUrbano() {
      if (this.$store.state.esOscuro) {
        return 'E97FBD';
      }
      return '79144c';
    },
    styleLetter() {
      if (this.$store.state.esOscuro) {
        return 'color:black';
      }
      return 'color:white';
    },
    styleUrbano() {
      if (this.$store.state.esOscuro) {
        return 'background-color: #F1B1D7 !important; color: black !important;';
      }
      return 'background-color: #d2127e !important;';
    },
    styleNoUrbano() {
      if (this.$store.state.esOscuro) {
        return 'background-color: #E97FBD !important; color: black !important;';
      }
      return 'background-color: #79144c !important;';
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
  },
  methods: {
    obtenerAvance(avance) {
      // console.log(avance);
      return Math.ceil(parseFloat(avance));
    },
  },
  watch: {
    eleccion: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.randomKey = Math.random();
        }
      },
    },
  },
};
</script>
